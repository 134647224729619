<style scoped lang="less">
.content-panel {
  margin: 10px;
  width: calc(100% - 20px);
  height: 70%;
  display: flex;
  justify-content: flex-start;
}
.equip-tree {
  flex: 0 200px;
  height: 100%;
  overflow: auto;
  border: 1px solid black;
}
.equip-transfer {
  height: 100%;
  flex: auto;
  margin: 0 10px;
}
.bottom-buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .el-button {
    margin: 0 30px;
  }
}
</style>

<template>
  <CmDialogBorder
    size="horizontal"
    :zIndex="zIndex"
    title="本地耗材录入"
    v-if="isShow"
    @close="Close"
  >
    <div class="content-panel">
      <LocalEquipTree class="equip-tree" @nodeClick="handleNodeClick"></LocalEquipTree>
      <cm-transfer
        ref="Transfer"
        class="equip-transfer"
        v-model="value"
        :renderData="transferData"
      ></cm-transfer>
    </div>
    <div class="bottom-buttons">
      <el-button type="primary" class="common-button commonBtn" v-debounce="Save">确定</el-button>
      <el-button type="danger" class="common-button commonBtn" v-debounce="Close">取消</el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import { mapState } from 'vuex'
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import LocalEquipTree from './LocalEquipTree.vue'
import CmTransfer from '../../../../components/CmTransfer.vue'
export default {
  name: 'LocalEquipDialog',
  components: {
    CmDialogBorder,
    LocalEquipTree,
    CmTransfer
  },
  props: {},
  data() {
    return {
      transferData: [],
      value: [],
      isShow: false,
      zIndex: PopupManager.nextZIndex()
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleNodeClick(type) {
      this.$api
        .get(`/v1/webconsole/equipment/getEquipmentShowByEquipmentType?equipmentType=${type}`)
        .then((res) => {
          let data = res.data.data
          this.transferData = data.map((e, idx) => {
            let obj = {
              ...e,
              key: idx,
              disabled: false
            }
            Reflect.deleteProperty(obj, 'equipmentIndex')
            return obj
          })
        })
    },
    Close() {
      this.isShow = false
      this.value = []
    },
    Save() {
      const { studyDate, patientsID, patientsName } = this.currentPatient
      let items = this.value.map((e) => {
        return {
          ...e,
          expiryDate: '',
          equipmentName: e.equipmentShow,
          num: 1,
          surgeryDate: studyDate,
          patientsID,
          patientsName,
          studyDate,
          surgeryBillNo: patientsID
        }
      })
      let arr = []
      items.map((e) => {
        arr.push(e)
      })
      this.$api.post('/v1/webconsole/equipment/saveCISEquipmentList', arr).then(
        () => {
          this.$emit('save')
          this.isShow = false
        },
        (err) => console.log(err)
      )
      // let promises = items.map((e) => {
      //   return this.$api.post('/v1/webconsole/equipment/saveCISEquipment', e)
      // })
      // Promise.all(promises).then(
      //   () => {
      //     this.$emit('save')
      //     this.isShow = false
      //   },
      //   (err) => console.log(err)
      // )
      this.value = []
    },
    Show() {
      this.$refs.Transfer?.Clear()
      this.isShow = true
    }
  }
}
</script>
