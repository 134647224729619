<style scoped lang="less">
.content-panel {
  margin: 10px;
  width: calc(100% - 20px);
  height: 70%;
  display: flex;
  justify-content: flex-start;
}
.equip-tree {
  flex: 0 200px;
  height: 100%;
  overflow: auto;
  border: 1px solid black;
}
.equip-transfer {
  height: 100%;
  flex: auto;
  margin: 0 10px;
}
.bottom-buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .el-button {
    margin: 0 30px;
  }
}
</style>

<template>
  <CmDialogBorder
    size="horizontal"
    :zIndex="zIndex"
    title="HIS耗材录入"
    v-if="isShow"
    @close="Close"
  >
    <el-card>
      <el-button type="success" size="mini" class="commonBtn" @click="save">确定</el-button>
      <el-button type="danger" size="mini" class="commonBtn" @click="Close">取消</el-button>
      <el-table
        ref="multipleTable"
        :data="list_data"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 10px"
        height="400"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        border
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <!-- <el-table-column prop="OperationBillNo" label="手术单号"></el-table-column>
        <el-table-column prop="EquipmentName" label="耗材名称"></el-table-column>
        <el-table-column prop="Specification" label="耗材规格"></el-table-column>
        <el-table-column prop="SupplierName" label="供应商名称"></el-table-column>
        <el-table-column prop="ApplyDepartment" label="申请科室"></el-table-column>
        <el-table-column prop="PatientID" label="患者病案号"></el-table-column>
        <el-table-column prop="PatientName" label="患者姓名"></el-table-column>
        <el-table-column prop="OperationDate" label="手术日期"></el-table-column>
        <el-table-column prop="Price" label="价格"></el-table-column>
        <el-table-column prop="ExpireDate" label="有效期"></el-table-column>
        <el-table-column prop="ConsumeCount" label="消耗数量"></el-table-column>
        <el-table-column prop="ProducingArea" label="生产厂家"></el-table-column> -->
        <el-table-column prop="equipmentName" label="耗材名称"></el-table-column>
        <el-table-column prop="specification" label="耗材规格"></el-table-column>
        <el-table-column prop="consumeCount" label="消耗数量"></el-table-column>
        <el-table-column prop="price" label="价格"></el-table-column>
        <el-table-column prop="producingArea" label="生产厂家"></el-table-column>
        <el-table-column prop="supplierName" label="供应商"></el-table-column>
        <el-table-column prop="expireDate" label="有效期至"></el-table-column>
        <el-table-column prop="operationDate" label="手术日期"></el-table-column>
      </el-table>
    </el-card>
  </CmDialogBorder>
</template>

<script>
import { mapState } from 'vuex'
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'

export default {
  name: 'HISEquipDialog',
  components: {
    CmDialogBorder
  },
  props: {},
  data() {
    return {
      list_data: [],
      save_list: [],
      isShow: false,
      zIndex: PopupManager.nextZIndex()
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    Close() {
      this.isShow = false
    },
    Show() {
      this.isShow = true
      this.axios
        .post(`/v1/webconsole/sync/CISEquipment?patientID=${this.currentPatient.patientsID}`)
        .then((res) => {
          this.list_data = res.data.data
        })
    },
    handleSelectionChange(val) {
      this.save_list = val
    },
    // 保存
    save() {
      const newArr = this.save_list
      if (newArr.length > 0) {
        let items = newArr.map((e) => {
          return {
            equipmentId: '',
            equipmentName: e.equipmentName,
            equipmentType: e.specification,
            num: parseInt(e.ConsumeCount) || 1,
            price: e.price,
            brand: e.producingArea,
            country: '',
            category: e.specification,
            provider: e.supplierName,
            mainBarCode: '',
            batchCode: '',
            equipmentUnit: '',
            source: '',
            productLocation: e.producingArea,
            expiryDate: e.expireDate
          }
        })
        let arr = []
        items.map((e) => {
          arr.push(e)
        })
        this.$confirm('是否添加外部耗材记录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$api
              .post(
                `/v1/webconsole/equipment/operation/batch_out/${this.$route.query.operationId}`,
                arr
              )
              .then(
                (res) => {
                  this.$emit('HIS_save')
                  this.$message.success(res.data.msg)
                },
                (err) => console.log(err)
              )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消保存'
            })
          })
      } else {
        this.$message.error('请选择要添加的耗材记录')
      }
      this.isShow = false
    }
  }
}
</script>
