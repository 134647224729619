<template>
  <el-tree
    class="cm-tree"
    :data="renderDatas"
    :props="defaultProps"
    @node-click="handleNodeClick"
  ></el-tree>
</template>

<script>
export default {
  name: 'CmTree',
  components: {},
  props: {},

  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'groupName'
      },
      renderDatas: [],
      isLoadedData: false
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getActiveUrlData()
  },
  methods: {
    getActiveUrlData() {
      let resolveData = function (data, parentId) {
        let res
        res = data.filter((e) => e.parentId === parentId)
        for (let eq of res) {
          if (data.some((e) => e.parentId === eq.groupId)) {
            eq.children = resolveData(data, eq.groupId)
          }
        }
        return res
      }
      this.$api.get('/v1/webconsole/equipment/getEquipmentGroup').then((res) => {
        let data = res.data.data
        this.renderDatas = resolveData(
          data.filter((e) => e.isStudyProcessUse === 1),
          '1'
        )
        this.isLoadedData = true
      })
    },
    handleNodeClick(data) {
      if (Reflect.has(data, 'children')) return
      this.$emit('nodeClick', data.groupName)
    }
  }
}
</script>
