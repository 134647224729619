<style scoped lang="less">
.cm-transfer {
  height: 100%;
  margin: 0 10px;
  display: flex;
}
.inner-table {
  flex: 1;
  border: 1px solid black;
}
.inner-buttons {
  display: inline-block;
  margin: auto 15px;
}
</style>

<template>
  <div class="cm-transfer">
    <el-table
      ref="leftTable"
      :data="renderData"
      tooltip-effect="dark"
      class="inner-table"
      height="100%"
      @selection-change="handleLeftSelectionChange"
    >
      <slot name="tableColumn">
        <el-table-column type="selection" width="45"></el-table-column>
        <el-table-column prop="equipmentType" label="耗材类型" width="120"></el-table-column>
        <el-table-column
          prop="equipmentShow"
          label="耗材名称"
          show-overflow-tooltip
        ></el-table-column>
      </slot>
    </el-table>
    <div class="inner-buttons">
      <el-button
        type="danger"
        icon="el-icon-arrow-left"
        v-debounce="_delete"
        :disabled="rightSelection.length == 0"
      >
        {{ texts[0] }}
      </el-button>
      <el-button
        type="primary"
        icon="el-icon-arrow-right"
        v-debounce="_add"
        :disabled="leftSelection.length == 0"
      >
        {{ texts[1] }}
      </el-button>
    </div>
    <el-table
      ref="rightTable"
      :data="value"
      tooltip-effect="dark"
      class="inner-table"
      height="100%"
      @selection-change="handleRightSelectionChange"
    >
      <slot name="tableColumn">
        <el-table-column type="selection" width="45"></el-table-column>
        <el-table-column prop="equipmentType" label="耗材类型" width="120"></el-table-column>
        <el-table-column
          prop="equipmentShow"
          label="耗材名称"
          show-overflow-tooltip
        ></el-table-column>
      </slot>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'CmTransfer',
  components: {},
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    value: {
      type: Array,
      require: true
    },
    texts: {
      type: Array,
      default: () => ['移除', '添加']
    },
    renderData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      leftSelection: [],
      rightSelection: []
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleLeftSelectionChange(val) {
      this.leftSelection = val
    },
    handleRightSelectionChange(val) {
      this.rightSelection = val
    },
    _add() {
      this.$emit('change', [...this.value, ...this.leftSelection])
      this.$refs.leftTable.clearSelection()
    },
    _delete() {
      this.$emit(
        'change',
        this.value.filter((a) => !this.rightSelection.some((b) => a.key === b.key))
      )
      this.$refs.rightTable.clearSelection()
    },
    Clear() {
      this.rightSelection = []
    }
  }
}
</script>
