<style scoped lang="less">
.main-content {
  background: #eff0f7;
  .crt-content {
    .record-button-left {
      width: 54%;
    }
    .record-button-right {
      width: 45%;
      display: flex;
      align-items: center;
      .search-input {
        width: 200px;
      }
    }
  }
  .table-box {
    margin-top: 10px;
    margin-left: 10px;
    border-top-left-radius: 8px;
    /deep/.el-card__body {
      padding: 0;
      display: flex;
      height: calc(100vh - 3.125rem);
    }
    .left-box {
      width: 54%;
      .report-table {
        border: none;
      }
    }
    .medicine-box {
      width: 46%;
      .inner-content-right {
        width: 100%;
        height: 100%;
        .inner-tabs-right {
          border-left: 1px solid black;
          height: calc(100% - 2px);
        }
        .custom-tree-node {
          font-size: 13px;
          .key-node {
            color: #9b59b6;
          }
          .normal {
            color: #000;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="main-content">
    <div v-show="!isReadOnly" class="crt-content">
      <div class="record-button-left">
        <el-button
          type="primary"
          class="common-button commonBtn"
          icon="el-icon-download"
          @click="showHISEquipDialog"
          :disabled="isReadOnly"
        >
          HIS耗材导入
        </el-button>
        <el-button
          type="success"
          class="common-button commonBtn"
          icon="el-icon-refresh"
          v-debounce="_refresh"
          :disabled="isReadOnly"
        >
          刷新
        </el-button>
        <el-button
          type="danger"
          class="common-button commonBtn"
          icon="el-icon-delete"
          v-debounce="open_delete"
          :disabled="isReadOnly"
        >
          删除
        </el-button>
      </div>
      <div class="record-button-right">
        <el-button
          type="primary"
          class="common-button commonBtn"
          icon="el-icon-receiving"
          v-debounce="showLocalEquipDialog"
          :disabled="isReadOnly"
        >
          添加本地耗材
        </el-button>
      </div>
    </div>
    <el-card class="table-box">
      <div class="left-box">
        <el-table
          class="report-table"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          height="100%"
          highlight-current-row
          @current-change="handleCurrentChange"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column
            property="equipmentName"
            label="耗材名称"
            width="250"
            align="center"
          ></el-table-column>
          <el-table-column
            property="equipmentType"
            label="耗材规格"
            width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            property="productLocation"
            label="生产厂家"
            align="center"
          ></el-table-column>
          <el-table-column
            property="expiryDate"
            label="有效期"
            align="center"
            :formatter="get_expiryDate"
          ></el-table-column>
          <el-table-column property="num" label="数量" align="center"></el-table-column>
          <el-table-column property="price" label="价格" align="center"></el-table-column>
        </el-table>
      </div>
      <div class="medicine-box">
        <div class="inner-content-right">
          <el-tabs class="inner-tabs-right" type="border-card">
            <el-tab-pane
              v-for="(item, index) in renderData"
              :key="index"
              :label="item.data.groupName"
            >
              <el-tree
                class="cm-tree"
                :data="item.child"
                :props="defaultProps"
                :load="loadNode"
                lazy
                show-checkbox
                @check="handleTreeData"
              ></el-tree>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-card>
    <LocalEquipDialog ref="LocalEquipDialog" @save="handleSave"></LocalEquipDialog>
    <HISEquipDialog ref="HISEquipDialog" @HIS_save="_refresh"></HISEquipDialog>
  </div>
</template>

<script>
import LocalEquipDialog from './components/LocalEquipDialog.vue'
import HISEquipDialog from '@/views/Menu/menu-Consumables/components/HISEquipDialog'
import { mapState } from 'vuex'
export default {
  name: 'Consumables',
  components: {
    LocalEquipDialog,
    HISEquipDialog
  },
  props: {},
  data() {
    return {
      tableData: [],
      currentRow: '',
      isReadOnly: false,
      defaultProps: {
        children: 'child',
        label: 'groupName',
        isLeaf: 'leaf'
      },
      renderData: [],
      isLoadedData: false,
      clickData: [],
      checkedVal: []
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  },
  watch: {
    currentPatient: {
      handler(val) {
        if (val && val.uuid) {
          this._refresh()
        }
      }
    }
  },
  mounted() {
    this._refresh()
    this.getActiveUrlData()
  },
  methods: {
    _refresh() {
      if (this.currentPatient) {
        this.$api
          .get(`/v1/webconsole/equipment/operation/query/${this.$route.query.operationId}`)
          .then((res) => {
            let data = res.data.data
            this.tableData = data
          })
      }
    },
    handleSave() {
      this._refresh()
    },
    showLocalEquipDialog() {
      const newArr = this.checkedVal.filter((o) => o.equipmentType)
      if (newArr.length > 0) {
        let items = newArr.map((e) => {
          return {
            ...e,
            expiryDate: '',
            equipmentId: e.equipmentIndex,
            equipmentName: e.equipmentName,
            num: 1
          }
        })
        let arr = []
        items.map((e) => {
          arr.push(e)
        })
        this.$confirm('是否添加本地耗材记录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            console.log(arr)
            this.$api
              .post(
                `/v1/webconsole/equipment/operation/batch_local/${this.$route.query.operationId}`,
                arr
              )
              .then(
                (res) => {
                  this.$message.success(res.data.msg)
                  this._refresh()
                },
                (err) => console.log(err)
              )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消保存'
            })
          })
      } else {
        this.$message.error('请选择要添加的耗材记录')
      }
    },
    showHISEquipDialog() {
      this.$refs.HISEquipDialog.Show()
    },
    handleCurrentChange(val) {
      this.currentRow = val
    },
    open_delete() {
      if (!this.currentRow) return
      else {
        this.$confirm('是否删除所选耗材?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this._delete()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      }
    },
    _delete() {
      this.$api.post(`/v1/webconsole/equipment/operation/delete/${this.currentRow.uuid}`).then(
        () => {
          this.tableData = this.tableData.filter((e) => e.uuid !== this.currentRow.uuid)
          this.currentRow = null
        },
        (err) => console.err(err)
      )
    },
    get_surgeryDate(val) {
      return val.surgeryDate.substr(0, 10)
    },
    get_expiryDate(val) {
      if (val.expiryDate == '' || val.expiryDate == null) return
      else return val.expiryDate.substr(0, 10)
    },
    getActiveUrlData() {
      this.$api.get('/v1/webconsole/equipment/groups/tree').then((res) => {
        if (res.data && res.data.data) {
          this.renderData = res.data.data.child
        }
      })
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return node.data.forEach((ite) =>
          resolve([
            {
              groupName: ite.data.groupName,
              id: ite.id,
              child: ite.child
            }
          ])
        )
      }
      if (node.data.child) {
        let clickData = node.data.child.map((e) => {
          let obj = {
            groupName: e.data.groupName,
            ...e
          }
          return obj
        })
        return resolve(clickData)
      }
      this.$api.get(`/v1/webconsole/equipment/local/group/${node.data.id}`).then((res) => {
        this.clickData = res.data.data.map((e) => {
          let obj = {
            ...e,
            disabled: false
          }
          return obj
        })

        this.clickData.forEach((item) => {
          item.groupName = `${item.equipmentName}`
          item.leaf = true
        })

        resolve(this.clickData)
      })
    },
    handleTreeData(node, data) {
      this.checkedVal = data.checkedNodes
    }
  }
}
</script>
